<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="loading"
    :tabs="tabs"
    routeback="/fornecedor/cadastro/gateway"
  >
    <template v-slot:form>
      <v-row>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormSelect
            label="Provider"
            v-model="form.provider_name"
            :options="opcoesGateways"
            :validation="$v.form.provider_name"
            @change="changeProvider"
          />
        </v-col>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormField
            label="Nome"
            v-model="form.name"
            :validation="$v.form.name"
          />
        </v-col>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormField
            :label="labelId"
            v-model="form.merchant_id"
            :validation="$v.form.merchant_id"
            :error-message="gatewayErrors"
            hint="Seus dados estão criptografados para sua segurança"
          />
        </v-col>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormField
            :label="labelKey"
            v-model="form.merchant_key"
            :validation="$v.form.merchant_key"
            :error-message="gatewayErrors"
            hint="Seus dados estão criptografados para sua segurança"
          />
        </v-col>
        <v-col v-if="opcoesAntifraude.length > 0" sm="12" md="6" lg="4" xl="4">
          <FormSelect
            label="Antifraude"
            v-model="form.antifraude"
            :validation="$v.form.antifraude"
            :options="opcoesAntifraude"
          />
        </v-col>
        <v-col v-if="suportsDescription" sm="12" md="6" lg="4" xl="4">
          <FormField
            label="Descritor da fatura"
            v-model="form.descritor_fatura"
            :validation="$v.form.descritor_fatura"
            :maxLength="descritorLength"
          />
        </v-col>
        <v-col v-if="suportsCertificado" sm="12" md="6" lg="4" xl="4">
          <FormFile
            label="Certificado"
            v-model="form.certificado"
            :validation="$v.form.certificado"
            @file="onSelectCertificado"
          >
            <template v-slot:help v-id="form.certificado_url">
              <v-btn icon small target="_blank" :href="form.certificado_url">
                <v-icon size="12">fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
          </FormFile>
        </v-col>
        <v-col v-if="suportsSenhaCertificado" sm="12" md="6" lg="4" xl="4">
          <FormField
            label="Senha Certificado"
            v-model="form.certificado_senha"
          />
        </v-col>
        <v-col
          sm="12"
          md="6"
          lg="4"
          xl="4"
          v-for="input in adicionalInputs"
          :key="input.key"
        >
          <FormField
            :label="input.nome"
            v-model="form.adicional_config[input.key]"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="validate-error"
        v-if="gatewayErrors && gatewayErrors.custom"
      >
        <small>
          As credenciais informadas não puderam ser validadas, verifique com seu
          gateway se estão corretas para o ambiente configurado.
        </small>
        <small v-for="(log, index) in validate_logs.gateway" :key="index">
          {{ log }}
        </small>
      </v-row>
      <div>
        <LineDivider>
          <h4>Utilizar este gateway para</h4>
        </LineDivider>
        <v-row justify="center">
          <v-col
            sm="4"
            md="2"
            lg="2"
            xl="2"
            v-for="method in paymentsSuport"
            :key="method"
          >
            <FormSwitch
              :label="method"
              :value="true"
              readonly
              v-if="paymentsSuport.length == 1"
            />
            <FormSwitch
              v-model="form.suporta"
              :label="method"
              :val="method"
              v-else
            />
          </v-col>
          <v-col
            sm="12"
            v-if="$v.form.suporta.$dirty && !$v.form.suporta.required"
          >
            <v-alert dense outlined color="#ff5252"
              >Selecione ao menos uma opção</v-alert
            >
          </v-col>
        </v-row>
      </div>
      <div v-if="had3ds">
        <LineDivider>
          <h4>Autenticação 3DS 2.0</h4>
        </LineDivider>
        <v-row v-if="had3ds">
          <v-col sm="12" md="4" lg="4" xl="4">
            <FormField
              label="Código do Estabelecimento"
              v-model="form.establishment_code"
              help="Código do Estabelecimento do Cielo E-Commerce 3.0"
              mask="##########"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col sm="12" md="4" lg="4" xl="4">
            <FormField
              label="Nome do estabelecimento"
              v-model="form.merchant_name"
              help="Nome do estabelecimento registrado na Cielo"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col sm="12" md="4" lg="4" xl="4">
            <FormField
              label="Código de Categoria"
              v-model="form.mcc"
              help="Código de Categoria do estabelecimento"
              mask="####"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col sm="12" md="6" lg="6" xl="6">
            <FormField
              label="Client Id"
              v-model="form.client_id"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col sm="12" md="6" lg="6" xl="6">
            <FormField
              label="Client Secret"
              v-model="form.client_secret"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="hasBoleto && (hasNossoNumero || opcoesBancoBoleto.length > 0)">
        <LineDivider>
          <h4>Boleto</h4>
        </LineDivider>
        <v-row justify="center">
          <v-col sm="12" md="6" lg="4" xl="4" v-if="hasNossoNumero">
            <FormField
              help="Número sequencial utilizado na emissão do próximo boleto. A cada boleto emitido ele é incrementado em mais 1. Solicite ao seu banco que forneça esse número."
              label="Nosso número"
              v-model="form.nosso_numero"
              :validation="$v.form.nosso_numero"
            />
          </v-col>
          <v-col
            sm="12"
            md="6"
            lg="4"
            xl="4"
            v-if="opcoesBancoBoleto.length > 0"
          >
            <FormSelect
              help="Banco de onde o boleto será gerado."
              label="Banco"
              v-model="form.banco_boleto"
              :validation="$v.form.banco_boleto"
              :options="opcoesBancoBoleto"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="opcoesAntifraude.length > 0 && hasCredito">
        <LineDivider>
          <h4>Antifraude</h4>
        </LineDivider>
        <v-row></v-row>
        <v-row justify="center">
          <v-col sm="12" md="6" lg="4" xl="4" v-if="antifraudeUsername">
            <FormField
              label="Usuário"
              v-model="form.usuario_antifraude"
              :validation="$v.form.usuario_antifraude"
              :error-message="antifraudeErrors"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col sm="12" md="6" lg="4" xl="4" v-if="antifraudePassword">
            <FormField
              label="Senha"
              v-model="form.senha_antifraude"
              :validation="$v.form.senha_antifraude"
              :error-message="antifraudeErrors"
              hint="Seus dados estão criptografados para sua segurança"
            />
          </v-col>
          <v-col
            sm="12"
            md="6"
            lg="4"
            xl="4"
            v-for="input in antifraudeInputs"
            :key="input.key"
          >
            <FormField
              :label="input.nome"
              v-model="form.antifraude_config[input.key]"
            />
          </v-col>
          <v-col sm="12" md="6" lg="4" xl="4" v-if="suportsRisco">
            <FormField
              label="Risco máximo da transação"
              v-model="form.antifraude_confianca_transacao"
              :validation="$v.form.antifraude_confianca_transacao"
            />
            <small>
              Indique o risco máximo de uma transação para ser aprovada, onde
              "1" representa baixo risco e "100" alto risco
            </small>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="validate-error"
          v-if="antifraudeErrors && antifraudeErrors.custom"
        >
          <small>
            As credenciais informadas não puderam ser validadas, verifique com
            seu provedor de antifraude se estão corretas para o ambiente
            configurado.
          </small>
          <small v-for="(log, index) in validate_logs.antifraude" :key="index">
            {{ log }}
          </small>
        </v-row>
      </div>
      <div v-if="hasPix">
        <LineDivider>
          <h4>Pix</h4>
        </LineDivider>
        <v-row></v-row>
        <v-row justify="center">
          <v-col sm="12" md="6" lg="4" xl="4" v-if="suportsChavePix > 0">
            <FormField label="Chave pix" v-model="form.chave_pix" />
          </v-col>
          <v-col sm="12" md="4" lg="2" xl="2">
            <FormField 
              label="Expiração(min)" 
              @blur="onBlurExpiracao"
              v-model="form.expiracao_minutos" 
              help="Tempo de expiração do QRCode em minutos"
              typeField="number"
              step="1" />
          </v-col>
        </v-row>
      </div>
      <div v-if="hasMultiEnv">
        <LineDivider>
          <h4>Ambiente</h4>
        </LineDivider>
        <v-row justify="center">
          <FormRadioGroup
            v-model="form.ambiente"
            inline
            :radios="[
              { label: 'Produção', value: 'PRODUCAO' },
              { label: 'Homologação', value: 'HOMOLOGACAO' }
            ]"
          />
        </v-row>
      </div>
    </template>
    <template v-slot:info>
      <v-row v-if="webhook">
        <v-col sm="12">
          <div class="infogate">
            <div class="infogate-label">Webhook</div>
            <div class="infogate-value infogate-link">{{ webhook }}</div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="webhookAntifraude">
        <v-col sm="12">
          <div class="infogate">
            <div class="infogate-label">Webhook {{ form.antifraude }}</div>
            <div class="infogate-value infogate-link">
              {{ webhookAntifraude }}
            </div>
          </div>
        </v-col>
      </v-row>
      <template v-if="enderecoApi && enderecoApi.length > 0">
        <LineDivider>
          <h4>Endereços da api</h4>
        </LineDivider>
        <v-row>
          <v-col
            sm="12"
            md="6"
            lg="4"
            xl="4"
            v-for="(end, index) in enderecoApi"
            :key="end.code + '_' + end.env + '_' + index"
          >
            <div class="infogate">
              <div class="infogate-label">{{ end.nome }}</div>
              <small>{{ end.env }}</small>
              <div class="infogate-value infogate-link">{{ end.url }}</div>
            </div>
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormFile from '@/components/form/FormFile'
import { mapGetters } from 'vuex'
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'
import {
  GATEWAY_CRETE,
  GATEWAY_UPDATE,
  GATEWAY_LOAD
} from '@/store/actions/gateway'
import * as _ from 'lodash'
export default {
  name: 'GatewayForm',
  components: {
    SidePopup,
    FormSelect,
    FormField,
    FormSwitch,
    LineDivider,
    FormButton,
    FormRadioGroup,
    FormFile
  },
  validations: {
    form: {
      name: { required },
      provider_name: { required },
      antifraude: { required },
      merchant_id: {
        required: requiredIf(function() {
          return this.mode == 'add'
        })
      },
      merchant_key: {
        required: requiredIf(function() {
          return this.mode == 'add'
        })
      },
      descritor_fatura: {
        required: requiredIf(function() {
          return this.suportsDescription
        })
      },
      certificado: {
        required: requiredIf(function() {
          return this.suportsCertificado
        })
      },
      suporta: {
        required: requiredIf(function() {
          return this.paymentsSuport.length > 1
        })
      },
      antifraude_confianca_transacao: {
        required: requiredIf(function(model) {
          return model.suporta.includes('CREDITO') && model.antifraude_ativar
        })
      },
      nosso_numero: { numeric },
      banco_boleto: {
        required: requiredIf(function() {
          return this.hasBoleto && this.opcoesBancoBoleto.length
        })
      },
      usuario_antifraude: {
        required: requiredIf(function() {
          return this.antifraudeUsername && this.mode == 'add'
        })
      },
      senha_antifraude: {
        required: requiredIf(function() {
          return this.antifraudePassword && this.mode == 'add'
        })
      }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      name: '',
      provider_name: 'CIELO',
      merchant_id: '',
      merchant_key: '',
      descritor_fatura: '',
      antifraude: 'padrao',
      ambiente_antifraude: '',
      usuario_antifraude: '',
      senha_antifraude: '',
      suporta: [],
      nosso_numero: '',
      antifraude_ativar: true,
      antifraude_confianca_transacao: 50,
      ambiente: 'PRODUCAO',
      antifraude_config: {},
      certificado: null,
      certificado_file: null,
      certificado_senha: '',
      establishment_code: '',
      merchant_name: '',
      mcc: '',
      client_id: '',
      client_secret: '',
      expiracao_minutos: null
    },
    loading: false,
    validate_logs: {
      gateway: [],
      antifraude: []
    },
    gatewayErrors: {
      custom: ''
    },
    antifraudeErrors: {
      custom: ''
    },
    tabs: [
      {
        key: 'form',
        text: 'Formulário'
      },
      {
        key: 'info',
        text: 'Info'
      }
    ],
    adicional_config: {}
  }),
  methods: {
    getData(id) {
      this.loading = true
      this.$store
        .dispatch(GATEWAY_LOAD, id)
        .then(resp => {
          this.loadData(resp)
          this.loading = false
        })
        .catch(() => (this.loading = false))
    },
    loadData(gateway) {
      let ambiente = gateway.ambiente
      switch (gateway.provider_name.toUpperCase()) {
        case 'CIELO': {
          ambiente = gateway.endpoint_transacional.includes('sandbox')
            ? 'HOMOLOGACAO'
            : 'PRODUCAO'
          break
        }
        case 'REDECARD': {
          ambiente = gateway.endpoint_transacional.includes('desenvolvedores')
            ? 'HOMOLOGACAO'
            : 'PRODUCAO'
          break
        }
      }
      this.form = {
        ...gateway,
        suporta: (gateway.suporta || []).map(item => item.toUpperCase()),
        ambiente,
        banco_boleto: gateway.banco_boleto || 'BancoDoBrasil',
        antifraude_config: {
          ...(_.get(gateway, 'antifraude_config', {}) || {})
        },
        certificado_file: null,
        adicional_config: {
          ...(_.get(gateway, 'adicional_config', {}) || {})
        },
        expiracao_minutos: this.convertSegundosEmMinutos(gateway.expiracao)
      }
      this.form.suporta =
        this.form.suporta.length > 0
          ? this.form.suporta
          : [_.get(this.paymentsSuport, '0', 'CREDITO')] || ['CREDITO']

      if (!this.form.antifraude) {
        this.form.antifraude = 'padrao'
      }
    },
    changeProvider() {
      this.$v.form.$reset()
      this.form = {
        ...this.form,
        ambiente_antifraude: '',
        usuario_antifraude: '',
        senha_antifraude: '',
        suporta: [],
        nosso_numero: '',
        antifraude: 'padrao',
        antifraude_ativar: true,
        antifraude_confianca_transacao: 50,
        banco_boleto: 'BancoDoBrasil',
        antifraude_config: {},
        ambiente: 'PRODUCAO',
        adicional_config: {},
        expiracao_minutos: 4320
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.opcoesAntifraude.length) {
        this.form.antifraude_ativar = true
      } else {
        this.form.antifraude_ativar = false
        this.form.antifraude = null
        this.form.antifraude_ativar = false
        this.form.antifraude = null
        this.form.ambiente_antifraude = null
        this.form.usuario_antifraude = null
        this.form.senha_antifraude = null
      }
      this.loading = true
      this.antifraudeErrors.custom = ''
      this.gatewayErrors.custom = ''
      this.validate_logs = {
        gateway: [],
        antifraude: []
      }
      let payload = {
        ...this.form,
        suporta: JSON.stringify(
          (this.form.suporta || []).map(item => item.toUpperCase())
        ),
        antifraude_config: JSON.stringify(this.form.antifraude_config || {}),
        adicional_config: JSON.stringify(this.form.adicional_config || {}),
        expiracao: this.form.suporta.includes('PIX')
          ? this.convertMinutosEmSegundos(this.form.expiracao_minutos)
          : null
      }
      if (this.form.suporta <= 0) {
        payload.suporta = JSON.stringify(
          [_.get(this.paymentsSuport, '0', 'CREDITO')] || ['CREDITO']
        )
      }

      if (this.mode == 'add') {
        this.$store
          .dispatch(GATEWAY_CRETE, payload)
          .then(resp => {
            const { valida_gateway, valida_antifraude, gateway } = resp

            this.$vueOnToast.pop('success', 'Gateway cadastrado com sucesso')
            if (
              valida_gateway &&
              valida_gateway.valido &&
              (!valida_antifraude || valida_antifraude.valido)
            ) {
              this.onClose(true)
            } else {
              this.$router.replace(
                '/fornecedor/cadastro/gateway/' + _.get(gateway, 'idgateway')
              )
              this.mode = 'edit'
              this.getData(_.get(gateway, 'idgateway'))
              const antifraudeValido = _.get(valida_antifraude, 'valido', false)
              this.antifraudeErrors.custom = !antifraudeValido
                ? _.get(valida_antifraude, 'mensagem', '')
                : ''

              this.gatewayErrors.custom = !valida_gateway.valido
                ? _.get(valida_gateway, 'mensagem', '')
                : ''

              this.validate_logs = {
                gateway: !valida_gateway.valido
                  ? _.get(valida_gateway, 'log', []) || []
                  : [],
                antifraude: !antifraudeValido
                  ? _.get(valida_antifraude, 'log', []) || []
                  : []
              }
            }
            this.loading = false
          })
          .catch(() => (this.loading = false))
      } else {
        this.$store
          .dispatch(GATEWAY_UPDATE, payload)
          .then(resp => {
            const { valida_gateway, valida_antifraude } = resp

            this.$vueOnToast.pop('success', 'Gateway atualizado com sucesso')
            if (
              valida_gateway &&
              valida_gateway.valido &&
              (!valida_antifraude || valida_antifraude.valido)
            ) {
              this.onClose(true)
            } else {
              const antifraudeValido = _.get(valida_antifraude, 'valido', false)
              this.antifraudeErrors.custom = !antifraudeValido
                ? _.get(valida_antifraude, 'mensagem', '')
                : ''

              this.gatewayErrors.custom = !valida_gateway.valido
                ? _.get(valida_gateway, 'mensagem', '')
                : ''

              this.validate_logs = {
                gateway: !valida_gateway.valido
                  ? _.get(valida_gateway, 'log', []) || []
                  : [],
                antifraude: !antifraudeValido
                  ? _.get(valida_antifraude, 'log', []) || []
                  : []
              }
            }
            this.loading = false
          })
          .catch(() => (this.loading = false))
      }
    },
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/cadastro/gateway')
      }
    },
    unicoMetodo(metodo) {
      if (this.form.suporta.length === 1 || this.paymentsSuport.length === 1) {
        return (
          this.form.suporta.includes(metodo) ||
          (this.paymentsSuport.length === 1 &&
            this.paymentsSuport.includes(metodo))
        )
      }
      return false
    },
    onSelectCertificado(file) {
      this.form.certificado_file = file
    },
    onBlurExpiracao() {
      if(!this.form.expiracao_minutos) {
        this.$vueOnToast.pop('warning', 'Adicionado valor padrão 72 horas(4320 minutos)')
        this.form.expiracao_minutos = 4320
        return;
      }

      if(this.form.expiracao_minutos < 30) {
        this.$vueOnToast.pop('warning', 'Valor mínimo 30 minutos')
        this.form.expiracao_minutos = 30
      }
    },
    convertSegundosEmMinutos(segundos) {
      if(!segundos)
        return 0;
      return (segundos / 60)
    },
    convertMinutosEmSegundos(minutos) {
      if(!minutos)
        return null;
      return (minutos * 60)
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorTag',
      'getGatewaysOpcoes',
      'getAntifraudeOpcoes'
    ]),
    opcoesGateways() {
      return this.getGatewaysOpcoes.map(item => ({
        text: item.nome,
        value: item.id
      }))
    },
    listaAntifraude() {
      const antifraude = this.getAntifraudeOpcoes.map(item => ({
        text: item.nome,
        value: item.id
      }))

      return [
        {
          text: 'Padrão',
          value: 'padrao'
        },
        ...antifraude
      ]
    },
    configuracao() {
      return this.getGatewaysOpcoes.find(
        item => item.id === this.form.provider_name
      )
    },
    configuracaoAntifraude() {
      return this.getAntifraudeOpcoes.find(
        item => item.id === this.form.antifraude
      )
    },
    opcoesAntifraude() {
      const suportAntifraude =
        _.get(this.configuracao, 'opcoes.antifraude', []) || []

      return this.listaAntifraude.filter(item =>
        suportAntifraude.includes(item.value)
      )
    },
    title() {
      return this.mode == 'edit' ? 'Editar gateway' : 'Novo gateway'
    },
    labelId() {
      return _.get(this.configuracao, 'labels.merchantid', 'id') || 'Id'
    },
    labelKey() {
      return _.get(this.configuracao, 'labels.merchantkey', 'id') || 'Key'
    },
    antifraudeOptions() {
      return this.antifraude_options.map(antifraude => antifraude)
    },

    paymentsSuport() {
      return (
        _.get(this.configuracao, 'opcoes.pagamento', ['CREDITO']) || ['CREDITO']
      )
    },
    suportsDescription() {
      return _.get(this.configuracao, 'config.descritorFatura', false) || false
    },
    suportsRisco() {
      return _.get(this.configuracao, 'config.riscoAntifraude', false) || false
    },
    suportsChavePix() {
      return _.get(this.configuracao, 'config.chavePix', false) || false
    },
    suportsCertificado() {
      return _.get(this.configuracao, 'config.certificado', false) || false
    },
    suportsSenhaCertificado() {
      return _.get(this.configuracao, 'config.senhaCertificado', false) || false
    },
    enviromentsSuport() {
      return _.get(this.configuracao, 'ambientes') || null
    },
    hasBoleto() {
      return this.form.suporta.includes('BOLETO') || this.unicoMetodo('BOLETO')
    },
    hasCredito() {
      return (
        this.form.suporta.includes('CREDITO') || this.unicoMetodo('CREDITO')
      )
    },
    hasPix() {
      return this.form.suporta.includes('PIX') || this.unicoMetodo('PIX')
    },
    hasNossoNumero() {
      return _.get(this.configuracao, 'config.nossonumero', false) || false
    },
    hasMultiEnv() {
      return (
        this.enviromentsSuport &&
        this.enviromentsSuport['producao'] &&
        this.enviromentsSuport['homologacao']
      )
    },
    opcoesBancoBoleto() {
      const bancos = _.get(this.configuracao, 'opcoes.banco', []) || []
      return bancos.map(item => ({ text: item.nome, value: item.id }))
    },
    antifraudeUsername() {
      return (
        _.get(this.configuracaoAntifraude, 'config.username', false) || false
      )
    },
    antifraudePassword() {
      return (
        _.get(this.configuracaoAntifraude, 'config.password', false) || false
      )
    },
    antifraudeInputs() {
      return _.get(this.configuracaoAntifraude, 'inputs', [])
    },
    adicionalInputs() {
      return _.get(this.configuracao, 'inputs.config', [])
    },
    descritorLength() {
      return `${_.get(this.configuracao, 'config.descritorLength', '13') ||
        '13'}`
    },
    webhook() {
      return _.get(this.configuracao, 'webhook') || null
    },
    webhookAntifraude() {
      return _.get(this.configuracaoAntifraude, 'webhook') || null
    },
    enderecoApi() {
      const producao = _.get(this.enviromentsSuport, 'producao', []) || []
      const homologacao = _.get(this.enviromentsSuport, 'homologacao', []) || []
      return [
        ...producao.map(item => ({ ...item, env: 'Produção' })),
        ...homologacao.map(item => ({ ...item, env: 'Homologação' }))
      ]
    },
    had3ds() {
      return (
        this.form.provider_name === 'CIELO' &&
        this.form.suporta.includes('DEBITO')
      )
    }
  },
  created() {
    let idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.editable = true
    }
    if (!this.form.antifraude) {
      this.form.antifraude = 'padrao'
    }
  }
}
</script>

<style lang="scss">
.validate-error {
  flex-direction: column;
  align-items: center;
  color: var(--danger-color);
}
.infogate {
  width: 100%;
  .infogate-label {
    font-weight: 800;
  }
  .infogate-link {
    color: #1976d2;
  }
}
</style>
